<template>
  <b-modal
    id="upsert-user-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @hidden="reset"
    @shown="shown"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ title }}</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <form @submit.prevent="submit" class="text-left default-form">
      <template v-if="!user">
        <div class="form-group mb-3">
          <label class="form-label fw-bold">{{ $t('full_name') }}</label>
          <input type="text" class="form-control" :placeholder="$t('full_name')" v-model.trim="$v.form.name.$model" />
        </div>
      </template>
      <div class="form-group mb-3">
        <label class="form-label fw-bold">{{ $t('email') }}</label>
        <input type="email" class="form-control" placeholder="email@email.com.br" v-model.trim="$v.form.email.$model" />
      </div>
      <div class="form-group mb-3" v-if="!user || user.role.slug === 'user'">
        <label class="form-label fw-bold">
          {{ $t('permission_level') }}
          <span id="tooltip-roles">
            <i class="fa fa-question-circle"></i>
          </span>
          <b-tooltip custom-class="font-size-xSmall" target="tooltip-roles" triggers="hover">
            <p><strong>{{ $t('user_role.user_manager') }}:</strong> {{ $t('permission_user_manager') }}</p>
            <p class="mb-0"><strong>{{ $t('user_role.user') }}:</strong> {{ $t('permission_user') }}</p>
          </b-tooltip>
        </label>
        <div v-if="rolesLoader">
          <Loader
            :size="'small'"
            :align="'start'"
          />
        </div>
        <div class="custom-select" v-else>
          <select class="form-control" v-model.trim="$v.form.role_id.$model">
            <template v-if="sortedRoles.length > 0">
              <option v-for="(role, index) in sortedRoles" :key="index" :value="role.id">{{ $t('user_role.' + role.slug) }}</option>
            </template>
          </select>
        </div>
      </div>
      <div class="text-center mt-3">
        <Button
          type="submit"
          class="btn btn-primary"
          :disabled="$v.$invalid"
          :loading="loader"
        >
          {{ buttonText }}
        </Button>
      </div>
    </form>
  </b-modal>
</template>

<script>

import NotificationService from '@/modules/notifications/services/notifications-service'
import UsersService from '@/modules/users/services/users-service'
import { parseErrorResponseToArray } from '@/utils'
import { required, email } from 'vuelidate/lib/validators'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'UpsertUserModal',
  props: ['user'],
  components: {
    AlertWraper,
    Button,
    Loader
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false,
      rolesLoader: false,
      roles: [],
      form: {
        name: '',
        email: '',
        role_id: ''
      }
    }
  },
  validations () {
    const obj = {
      form: {
        email: { required, email },
        role_id: { required }
      }
    }
    if (!this.user) {
      obj.form.name = { required }
    }
    return obj
  },
  computed: {
    buttonText () {
      return this.user ? this.$t('save') : this.$t('create_user')
    },
    sortedRoles () {
      const roles = Object.assign(this.roles, {})
      return roles.sort((a, b) => a.name.localeCompare(b.name))
    },
    title () {
      return this.user ? this.$t('edit_user') : this.$t('create_user')
    }
  },
  methods: {
    /**
     * Get roles
     */
    async getRoles () {
      this.rolesLoader = true
      this.content = null
      try {
        const roles = await UsersService.getUserRoles()
        this.roles = roles.data
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.rolesLoader = false
    },
    reset () {
      this.form.name = ''
      this.form.email = ''
      this.form.role_id = ''
    },
    /**
     * Set user data to form
     */
    setUser () {
      if (this.user) {
        this.form.email = this.user.email
        this.form.role_id = this.user.role.id
      } else {
        this.form.name = ''
        this.form.email = ''
        this.form.role_id = ''
      }
      // Get roles
      if (this.roles.length === 0) this.getRoles()
    },
    /**
     * On modal shown
     */
    shown () {
      this.setUser()
    },
    /**
     * Submit form
     */
    async submit () {
      this.loader = true
      this.content = null
      try {
        let user = null
        if (!this.user) user = await UsersService.createUser(this.form)
        else user = await UsersService.updateUser(this.user.id, this.form)
        const notification = {
          notification: {
            type: 'success',
            content: this.user ? this.$t('user_updated_successfully') : this.$t('user_created_and_completion_email_sent')
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.$bvModal.hide('upsert-user-modal')
        if (this.user) this.$emit('user-updated', user)
        else this.$emit('user-created', user)
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
